import React from 'react'
import { RichText } from 'prismic-reactjs'
import { Link, StaticQuery, graphql } from 'gatsby'
import { linkResolver } from '../06 Utilities/linkResolver'
import Social from '../01 Atoms/socialIcons'
import Mailchimp from '../02 Molecules/mailchimpForm'

export default footer => (
    <StaticQuery
      query={graphql`
        query footerQuery {
            prismic {
                allNavigations {
                    edges {
                        node {
                        contact_email
                        copyright
                        inverted_logo
                        footer_links {
                            link {
                            ... on PRISMIC_Content {
                                _linkType
                                _meta {
                                    type
                                    uid
                                }
                                title
                            }
                            }
                        }
                        }
                    }
                }
            }
        }            
      `}
      render={data => (
        <>
        {data.prismic.allNavigations.edges.map(({ node }) => (
        <footer className="footer">
            <div className="wrapper footer--wrapper">
                <div className="logo">
                    <img className="logo__image" src={node.inverted_logo.url} alt={node.inverted_logo.alt} />
                </div>
                <div className="footer__navigation">
                    <ul className="footer__navigation__links">
                        {node.footer_links.map(({ link }) => (
                            <li><Link activeClassName="active" to={linkResolver(link)}>{RichText.asText(link.title)}</Link></li>
                        ))}
                    </ul>
                    <span>{ node.copyright }</span>
                </div>
                <div className="contact-links">
                    <a className="contact" title="Contact email address" href={"mailto:" + RichText.asText(node.contact_email)}>{RichText.asText(node.contact_email)}</a>
                    <Social />    
                </div>
                <div className="email-form">
                    <Mailchimp />
                </div>
            </div>
        </footer>
        ))}
        </>
      )}
    />
  )


  