import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import { linkResolver } from '../06 Utilities/linkResolver'
import { slide as Menu } from 'react-burger-menu'
import { RichText } from 'prismic-reactjs'

const styles = {
    bmBurgerButton: {
        position: 'fixed',
        width: '60px',
        height: '60px',
        borderRadius: '100px',
        left: '0',
        top: '5.5rem',
        zIndex: 99,
    },
    bmCrossButton: {
        height: '45px',
        width: '45px'
    },
    bmBurgerBars: {
        height: '5px',
        top: '0',
        position: 'relative',
    },
    bmMenu: {
        display: "block"
    },
    bmItemList: {
        height: "calc(100% - 8rem)"
    }
}

export default function BurgerMenu(props) {
    return (
    <StaticQuery
      query={graphql`
        query MobileNavQuery {
            prismic {
                allNavigations {
                    edges {
                        node {
                            inverted_logo
                            footer_links {
                                link {
                                    ... on PRISMIC_Content {
                                        _linkType
                                        _meta {
                                            type
                                            uid
                                        }
                                        title
                                    }
                                }
                            }                            
                            primary_navigation_links {
                                link_text
                                link {
                                    ... on PRISMIC__ExternalLink {
                                        _linkType
                                        url
                                    }
                                    ... on PRISMIC_Landing_page {
                                        _meta {
                                        type
                                        uid
                                        }
                                    }
                                    ... on PRISMIC_News_content {
                                        _meta {
                                        type
                                        uid
                                        }
                                    }                                    
                                    ... on PRISMIC_Contact_form {
                                        _meta {
                                        type
                                        uid
                                        }
                                    }                                    
                                    ... on PRISMIC_Gallery {
                                        _meta {
                                        type
                                        uid
                                        }
                                    }                                    
                                }
                            }
                        }
                    }
                }
            }
        }
      `}
      render={data => <MobileMenu data={data} {...props} />}
    />
  )}

  const MobileMenu = ( data ) => (
    <Menu styles={styles} burgerButtonClassName={data.inView ? 'inactive' : 'active'} className="nav nav--mobile">
        {data.data.prismic.allNavigations.edges.map(({ node }, index) => (
        <div key={index}>
            <div className="logo">
                <Link to='/'><img className="logo__image" src={node.inverted_logo.url} alt={node.inverted_logo.alt} /></Link>
            </div>                
           <div className="menu-wrap">
            <ul>
                    {node.primary_navigation_links.map(({ link, link_text }, index) => (
                    <li key={index}>
                        {link.__typename === "PRISMIC__ExternalLink" ? 
                        <a key={link.url} type={link.__typename} href={link.url}>{link_text[0].text}</a>
                        : 
                        <Link activeClassName="active" key={link} type={link.__typename} to={(linkResolver(link))}>{link_text[0].text}</Link>
                        }
                    </li>
                    ))}
                </ul>
                <div>
                    <ul className="about-links">
                        {node.footer_links.map(({ link }, index) => (
                        <li key={index}>
                            <Link activeClassName="active" to={linkResolver(link)}>{RichText.asText(link.title)}</Link>
                        </li>
                        ))}        
                    </ul>
                </div>
            </div>
        </div>
        ))}
    </Menu>
  )
