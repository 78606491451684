import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import PrimaryNaviation from '../02 Molecules/nav-primary'
import SecondaryNaviation from '../02 Molecules/nav-secondary'
import BurgerMenu from '../02 Molecules/nav-mobile'
import { InView } from 'react-intersection-observer'
import Search from '../01 Atoms/searchContainer';

export default function header() {
    return (
    <StaticQuery
      query={graphql`
        query HeaderQuery {
            prismic {
                allNavigations {
                    edges {
                        node {
                            contact_email
                            site_logo     
                            site_name
                        }
                    }
                }
            }
        }            
      `}
      render={data => <Header data={data} />}
    />
  )
}

const Header = ( { data } ) => (
    <>
    {data.prismic.allNavigations.edges.map(({ node }, index) => (
    <InView>
    {({ inView, ref, entry }) => (        
    <header key={index} ref={ref}>
        <SecondaryNaviation />
        <div className="masthead">
            <div className="navigation--mobile">
                <BurgerMenu inView={inView} />
            </div>
            <div className="logo">
                <Link title="Homepage" to="/" >
                    <img className="logo__image" src={node.site_logo.url} alt={node.site_logo.alt} />
                </Link>
            </div>
            <Search className="color" />
        </div>
        <PrimaryNaviation />
    </header>
    )}
    </InView>    
    ))}
    </>
)