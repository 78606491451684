import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { FaFacebookF } from 'react-icons/fa'
import { FaInstagram } from 'react-icons/fa'
import { FaTwitter } from 'react-icons/fa'
import { FaPinterest } from 'react-icons/fa'
import { FaYoutube } from 'react-icons/fa'

export default Social => (
    <StaticQuery
      query={graphql`
        query SocialQuery {
            prismic {
                allNavigations {
                    edges {
                        node {
                            facebook {
                                ... on PRISMIC__ExternalLink {
                                _linkType
                                url
                                }
                            }
                            instagram {
                                ... on PRISMIC__ExternalLink {
                                _linkType
                                url
                                }
                            }
                            pinterest {
                                ... on PRISMIC__ExternalLink {
                                _linkType
                                url
                                }
                            }
                            twitter {
                                ... on PRISMIC__ExternalLink {
                                _linkType
                                url
                                }
                            }
                            youtube {
                                ... on PRISMIC__ExternalLink {
                                _linkType
                                url
                                }
                            }
                        }
                    }
                }
            }
        }
      `}
      render={data => (
        <>
        {data.prismic.allNavigations.edges.map(({ node, index }) => (
            <ul className="social-links">
                {node.pinterest ? 
                <a target="_blank" rel="noreferrer" key={index} href={node.pinterest.url} title="Pinterest page"><li><FaPinterest /></li></a>
                : ""}
                {node.instagram ? 
                <a target="_blank" rel="noreferrer" href={node.instagram.url} title="Instagram page"><li><FaInstagram /></li></a>
                : ""}
                {node.facebook ? 
                <a target="_blank" rel="noreferrer" href={node.facebook.url} title="Facebook page"><li><FaFacebookF /></li></a>
                : ""}
                {node.twitter ? 
                <a target="_blank" rel="noreferrer" href={node.twitter.url} title="Twitter page"><li><FaTwitter /></li></a>
                : ""}
                {node.youtube ? 
                <a target="_blank" rel="noreferrer" href={node.youtube.url} title="YouTube page"><li><FaYoutube /></li></a>
                : ""}
        </ul>
        ))}
        </>
      )}
    />
  )
