import React, { useState } from 'react';
// import { fuse } from '../06 Utilities/fuse-helper';
import Fuse  from 'fuse.js';
import fetch from 'node-fetch';
import { Link } from 'gatsby'
import SearchIcon from './search.inline.svg'

let url = "https://stoic-jennings-d275f4.netlify.app/index.json";

const options = {
  shouldSort: true,
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 3,
  // this should be templated at build time
  keys: [
    { name: 'title', weight: 0.9 },
    { name: 'text', weight: 0.3 },
    { name: 'headings', weight: 0.9 },
    { name: 'path', weight: 0.9 },
  ]
};

let result;
let searchIndex;
let fuse;
let _fuse;

function Search(props) {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [active, setActive] = useState('');
  const [visible, setVisibility] = useState(false);

  const handleChange = event => {
    setSearchTerm(event.target.value);
  };

  const handleEnter = async (event) => {
    if (event.keyCode !== 27) {
      
      searchIndex =
      await fetch(url)
      .then(res => res.json())
      .catch(err => { throw err });

      let indexMap = Object.entries(await searchIndex).map(([k, v]) => {
        return {
          path: k.substring(0, k.length - 10),
          ...v
        };
      });
      
      fuse = new Fuse(await indexMap, options);

      console.log(fuse);
      
      _fuse = fuse;
      
      result = fuse.search(searchTerm);

      // result = await fetch(`https://total-trips.netlify.app/.netlify/functions/search?search=` + searchTerm + `&limit=10`).then(x => { return x.json() });
      setResults(result);
      searchTerm ? setActive(result) : setActive(null);
    }
    if (event.keyCode === 27) {
      handleClose();
    }
  };

  const handleSearchBox = (event) => {
    event.stopPropagation();
    toggleSearchBox();
  }

  const toggleSearchBox = () => {
    visible ? setVisibility(false) : setVisibility(true);
  }
  
  const handleSubmit = async (event) => {
    searchIndex =
    await fetch(url)
    .then(res => res.json())
    .catch(err => { throw err });

    let indexMap = Object.entries(await searchIndex).map(([k, v]) => {
      return {
        path: k.substring(0, k.length - 10),
        ...v
      };
    });
    
    fuse = new Fuse(await indexMap, options);
    
    _fuse = fuse;
    
    result = fuse.search(searchTerm);
    // const result = await fetch(`https://total-trips.netlify.app/.netlify/functions/search?search=` + searchTerm + `&limit=10`).then(x => { return x.json() });
    setResults(result);
    searchTerm ? setActive(result) : setActive(null);    
    // const result = await fetch(`https://total-trips.netlify.app/.netlify/functions/search?search=${searchTerm}&limit=10`).then(x => { return x.json() });
    setResults(result);
    searchTerm ? setActive(result) : setActive(null);
  };

  const handleClose = async (event) => {
    if (visible) {
      setSearchTerm('');
      setActive(null);
      setVisibility(false);
    }
  };

  const noResults = () => {
    return (
      <div className="search-box__results__item">
          <p>Sorry, this search found no results</p>
      </div>
    )
  }

  const yesResults = (result, i) => {
    if (i < 8) {
    return (
      <div className="search-box__results__item" key={i}>
          <Link to={result.item.path}>{result.item.headings[0]}</Link>
      </div>
    )
    } else {
      return;
    }
  }

  return (
    <>
    <div className="search-wrapper" onKeyDown={handleEnter}>
      <div className="search">
          <button
              type="button"
              onClick={handleSearchBox}
              className={"button button--primary button--search " + props.className}
              text="Search"
          >
            <SearchIcon aria-hidden="true" /><span aria-hidden="false">Search</span>
          </button>
      </div>
    </div>
    <div className={visible ? "backdrop active" : "backdrop"} onClick={visible ? handleClose : ""} onKeyDown={handleEnter}></div>
    <div className={visible ? "search-box active" : "search-box"}>
      <div className="bm-cross-button-wrap">
        <div className="bm-cross-button" onClick={handleClose}>
          <button tabIndex="0">
            <span>
              <span className="bm-cross"></span>
              <span className="bm-cross"></span>
            </span>
          Close Menu</button>
        </div>
      </div>
      <div className="search-box__wrapper">
        <div className="search-box__form">        
          <label>Search
            <input
                className="search-box__input"
                type="text"
                value={searchTerm}
                onChange={handleChange}
                onKeyDown={handleEnter}
            />
          </label>
          <div className="button-wrap color">
            <button
                type="button"
                onClick={active ? handleClose : handleSubmit}
                className="button button--primary button--search color"
                text="Search"
            >
              <SearchIcon aria-hidden="true" /><span aria-hidden="false">Search</span>
            </button>          
          </div>
        </div>
        <div className={active ? "search-box__results active" : "search-box__results"}>
          {
            results[0] ?
              results.map((result, i) => {
                return yesResults(result, i);
              })
            : 
              noResults()
            }
        </div>
      </div>
    </div>
    </>
  );
}

export default Search;