import React from 'react'
import { RichText } from 'prismic-reactjs'
import { StaticQuery, graphql } from 'gatsby'
import Social from '../01 Atoms/socialIcons'
import Search from '../01 Atoms/searchContainer'
import uid from 'uid';

export default nav => (
    <StaticQuery
      query={graphql`
        query SecondaryNavQuery {
            prismic {
                allNavigations {
                    edges {
                        node {
                            contact_email
                            primary_navigation_links {
                                link {
                                    ... on PRISMIC__ExternalLink {
                                        _linkType
                                        url
                                    }
                                    ... on PRISMIC_Landing_page {
                                        title
                                        _meta {
                                            type
                                            uid
                                        }
                                    }
                                    ... on PRISMIC_News_content {
                                        title
                                        _meta {
                                            type
                                            uid
                                        }
                                    }                                    
                                }
                            }
                        }
                    }
                }
            }
        }
      `}
      render={data => (
        <nav className="nav nav--secondary gradient">
            {data.prismic.allNavigations.edges.map(({ node, index }) => (
                <>
                <div key={index + uid(32)} className="wrapper wrapper--full nav--secondary__wrapper">
                    <Social />
                    <a className="contact" title="Contact email address" href={"mailto:" + RichText.asText(node.contact_email)}>{RichText.asText(node.contact_email)}</a>
                    <Search />
                </div>
                </>
            ))}
        </nav>
      )}
    />
  )
