import React, { useState } from 'react';

import addToMailchimp from "gatsby-plugin-mailchimp"
import { useFormik } from "formik"

const MailChimpForm = () => {
  const [submission, setSubmission] = useState("");
  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      state: "clear",
    },
    onSubmit: values => {
      addToMailchimp(values.email, {FNAME: values.firstName})
      .then(data => {
        if (data.result === "error") {
          setSubmission("error");

          if (data.msg === `Recipient "` + values.email + `" has too many recent signup requests`) {
            formik.values.state = "Too many recent signup requests, please try again later";
          }
          else if (data.msg === `The email you entered is not valid.`) {
            formik.values.state = "The email you entered is not valid";
          }
          else if (data.msg.includes('already subscribed to list')) {
            formik.values.state = "You are already subscribed";
          }
          else {
            formik.values.state = "There seems to have been a error";
          }

        } else {
          formik.values.state = "success";
          setSubmission("success");
      }})
    },
  })
  return (
    <>
    <form className={formik.values.state === "clear" ? "clear form form--email" : "form form--email"} onSubmit={formik.handleSubmit}>
        <label className="clear" style={{ fontSize : "1rem" }} htmlFor="email-2">Enter your email address to sign up for our newsletter:</label>
        <div className="input-wrap">
            <input
            id="email-2"
            name="email"
            type="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            placeholder="Your email address"
            />
            <button className="button--filled" type="submit"><span>Submit</span></button>
        </div>
        <p className="clear">{formik.values.state === "clear" ? "" : formik.values.state === "success" ? "All done!" : formik.values.state}</p>
    </form>
    </>
  )
}

export default MailChimpForm