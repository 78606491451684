import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import { linkResolver } from '../06 Utilities/linkResolver'
import uid from 'uid';

export default nav => (
    <StaticQuery
      query={graphql`
        query PrimaryNavQuery {
            prismic {
                allNavigations {
                    edges {
                        node {
                            primary_navigation_links {
                                link_text
                                link {
                                    ... on PRISMIC__ExternalLink {
                                        _linkType
                                        url
                                    }
                                    ... on PRISMIC_Landing_page {
                                        _meta {
                                        type
                                        uid
                                        }
                                    }
                                    ... on PRISMIC_News_content {
                                        _meta {
                                        type
                                        uid
                                        }
                                    }                                    
                                    ... on PRISMIC_Contact_form {
                                        _meta {
                                        type
                                        uid
                                        }
                                    }                                    
                                    ... on PRISMIC_Gallery {
                                        _meta {
                                        type
                                        uid
                                        }
                                    }                                    
                                }
                            }
                        }
                    }
                }
            }
        }
      `}
      render={data => (
        <nav className="nav nav--primary">
            {data.prismic.allNavigations.edges.map(({ node, index }) => (
                <ul key={index + uid(32)}>
                    {node.primary_navigation_links.map(({ link, link_text, index }) => (
                        <li key={index + uid(32)}>
                        {link.__typename === "PRISMIC__ExternalLink" ? 
                            <a key={link.url} type={link.__typename} href={link.url}>{link_text[0].text}</a>
                        : 
                            <Link partiallyActive activeClassName="active" key={link} type={link.__typename} to={(linkResolver(link))}>{link_text[0].text}</Link>
                        }
                        </li>
                    ))}
                </ul>
            ))}
        </nav>
      )}
    />
  )
